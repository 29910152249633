import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import {Helmet} from "react-helmet"
import device from "../styles/device"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import "moment/locale/ca"
import Layout from "../layout"
import Emojis from "../components/emojis"
import IconType1 from "../images/icon_post_type1.svg"
import IconType2 from "../images/icon_post_type2.svg"
import IconType3 from "../images/icon_post_type3.svg"
import { useTrail, useSpring, animated } from 'react-spring'

//data:
import langData from '../data/languages/ca'


const BlogContainer = styled.section`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  max-width: 1440px;
  
  margin: auto;
  padding: 100px 8.3333333%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  margin: 100px auto 0;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const BlogRow = styled.div`    
    margin: 0 -10px;
`

const BlogTitle = styled.h1`
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -2px;
  line-height: 54px;
  margin: 0;

  padding: 0 10px;

  @media ${device.phone} {
    font-size: 40px;
    line-height: 45px;
  }
`

const BlogSubtitle = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin: 10px 0 140px;

  padding: 0 10px;

  @media ${device.phone} {
    margin: 10px 0 60px;
  }
`

const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;

  padding: 0 10px;

  @media ${device.phone} {
    margin: 0;
  }
`

const Post = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;

  @media ${device.phone} {
    flex-direction: column;
    height: auto;
  }
`

const PostIcon = styled.img`
  margin: 0px 40px 0 0;
  width: 100px;
  height: 90px;
`

const PostInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PostDate = styled.div`
  font-size: 14px;
  line-height: 25px;
  margin: 0 0 10px;

  @media ${device.phone} {
    margin: 15px 0 10px;
  }
`

const PostTitle = styled.h2`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 32px;
  margin: 0;

  a {
    text-decoration: none;
    color: ${colors.tertiary};

    :hover {
      cursor: pointer;
      text-decoration: underline;
    }

    @media ${device.tablet} {
      font-size: 23px;
      line-height: 26px;
    }
  }
`

const PostCategory = styled.p`
  font-size: 12px;
  line-height: 25px;
  color: blue;
  margin: 10px 0 0;

  @media ${device.phone} {
    margin: 20px 0 0;
  }
`

const PostContainer = styled.div`
  height: fit-content;
  width: 100%;
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 60px;
  
  + div {
    margin-top: 60px;
  }

  @media ${device.phone} {
    padding-bottom: 40px;
  
    + div {
      margin-top: 40px;
    }
  }
`

const Blog = ({ data }) => {

  const {sideMenu, cookies, footer, blog: {helmet, title, subTitle}} = langData;

  const icon = iconType => {
    if (iconType.length === 1) {
      if (iconType[0].name === "Diseño web") return <PostIcon alt="postTypeIcon" src={IconType1} />
      if (iconType[0].name === "Desarrollo web") return <PostIcon alt="postTypeIcon" src={IconType2} />
    }    
    return <PostIcon alt="postTypeIcon" src={IconType3} />
  }

  const renderCatalanCategories = categories => categories.map(category => category.name === "Diseño web" ? "Disseny web" :"Desenvolupament web").join(', ');

  const titleProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(-200px)`
    },
    to: {
      opacity: 1,
      transform: `translateY(0px)`
    }
  })

  const trail = useTrail(data.allWordpressPost.edges.length, {
    opacity: 1,
    transform: `translateY(0px)`,
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    },
  })

  const AnimatedPostContainer = animated(PostContainer)

  return (
    
    <Layout sideMenu={sideMenu} cookies={cookies} footer={footer}>

      <Helmet>
          <title>{helmet.title}</title>
          <meta name="description" content={helmet.meta} />
      </Helmet>

      <BlogContainer>
        <BlogRow>

          <animated.div style={titleProps}>
            <BlogTitle>{title}<Emojis symbol="🦄" label="unicorn" />
            </BlogTitle>
            <BlogSubtitle>{subTitle}</BlogSubtitle>
          </animated.div>

          <PostsContainer>


            {
              trail.map((props, index) => {
                
                const categories = data.allWordpressPost.edges[index].node.categories
                const slug = data.allWordpressPost.edges[index].node.slug
                const title = data.allWordpressPost.edges[index].node.title
                const date = data.allWordpressPost.edges[index].node.date
                moment.locale("ca")
                const catalanDate = moment(date).format("LL");

                return (
                    <AnimatedPostContainer style={props} key={slug+index}>
                      <Post>
                        {icon(categories)}
                        <PostInfo>
                          <PostDate
                            dangerouslySetInnerHTML={{ __html: catalanDate }}
                            />
                          <PostTitle>
                            <Link to={sideMenu.primary[4].slug + slug} dangerouslySetInnerHTML={{ __html: title }}/>
                          </PostTitle>
                          <PostCategory>{renderCatalanCategories(categories)}</PostCategory>
                        </PostInfo>
                      </Post>
                    </AnimatedPostContainer>
                )
              })
            }
          </PostsContainer>

        </BlogRow> 
      </BlogContainer>

    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allWordpressPost(filter: {wordpress_site: {eq: "ca"}}, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          date
          title
          excerpt
          slug
          categories {
            name
          }
        }
      }
    }
  }
`
